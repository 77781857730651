import useAsyncFetch from "./useAsyncFetch";
import useIsGuest from "./useIsGuest";

const useGetQuestData = ({ cached = true }: { cached?: true } = {}) => {
	const isGuest = useIsGuest();
	const asyncData = useAsyncFetch({
		path: "/rest/quest/",
		method: "get",
		options: {
			cached,
			immediate: !isGuest.value,
			watch: [() => isGuest.value],
			transform: (data) => ({ ...data })
		}
	});
	return {
		...asyncData
	};
};

export default useGetQuestData;
